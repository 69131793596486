import { default as _91slug_93YSxdF3rKrJMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue?macro=true";
import { default as _91slug_931lDBbiJxzoMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue?macro=true";
import { default as _91name_93JhvCpTXa8MMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue?macro=true";
import { default as _91id_93pMVONIKi22Meta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/payment/[id].vue?macro=true";
import { default as _91id_93SpOCnLuw1XMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/omdome/[id].vue?macro=true";
import { default as _91tab_939QYGNRSsUQMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue?macro=true";
import { default as _91tab_930tlYt8abVDMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue?macro=true";
import { default as _91tab_93LLkCSL4OfCMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue?macro=true";
import { default as _91tab_930D4Wds7qlwMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue?macro=true";
import { default as _91slug_93rAglRkd2a8Meta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/info/[slug].vue?macro=true";
import { default as _91id_93NVeJdDwWJXMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/fn/[id].vue?macro=true";
import { default as _91tab_93ZQp1YORoDDMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue?macro=true";
import { default as _91slug_93wW6bbJt3ZDMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/competition/[slug].vue?macro=true";
import { default as _91slug_93hSMSLzevXiMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue?macro=true";
import { default as _91ocr_93Iskx4BaG3TMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue?macro=true";
import { default as _91ocr_93d2cKZNSqZMMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue?macro=true";
import { default as _91id_93ycJ2c9ggxlMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue?macro=true";
import { default as _91step_93aUmaOjoZF5Meta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue?macro=true";
import { default as _91slug_93ueokcr4WpBMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue?macro=true";
import { default as _91jwt_93UJfDH4pPYGMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue?macro=true";
import { default as _91tab_93hfViW7zubcMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue?macro=true";
import { default as activitiesBJLVGa0KSDMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/activities.vue?macro=true";
import { default as indexFORIIXZBBCMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/app/index.vue?macro=true";
import { default as callback4dev7foZvKMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/callback.vue?macro=true";
import { default as blackweekJ7ZVbnKBFNMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/blackweek.vue?macro=true";
import { default as _91slug_93CsUTWeb3a2Meta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/[slug].vue?macro=true";
import { default as indexP4wA4IsBLWMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/index.vue?macro=true";
import { default as byebyeieWJ89nkWanwMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/byebyeie.vue?macro=true";
import { default as indexzaokc4rScsMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/index.vue?macro=true";
import { default as lastminutezXDkEsMj0xMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/lastminute.vue?macro=true";
import { default as _91name_93UmZ7n0uIdwMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/[name].vue?macro=true";
import { default as index6lWQ9ImBr5Meta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/index.vue?macro=true";
import { default as catalogue5Y0oqvUoRDMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/catalogue.vue?macro=true";
import { default as complaint6KwVUoimMtMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/complaint.vue?macro=true";
import { default as dinbokning_45aktuellpuJMduEb3OMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue?macro=true";
import { default as _91slug_93Xr0j9eYu5TMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/[slug].vue?macro=true";
import { default as indexo42l19JkAfMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/index.vue?macro=true";
import { default as faqP6m9z10FBgMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/faq.vue?macro=true";
import { default as _91id_93QK4HnW5ac3Meta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue?macro=true";
import { default as index11RTUaPsy9Meta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/giftcard/index.vue?macro=true";
import { default as _91name_93jGsRfZrCs7Meta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/hotels/[name].vue?macro=true";
import { default as indexFjsTglkgyBMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/hotels/index.vue?macro=true";
import { default as indexOEB3jWlBhBMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/index.vue?macro=true";
import { default as grouptravelrGy9p8xiDSMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/grouptravel.vue?macro=true";
import { default as indexXZGm4o1YsTMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/index.vue?macro=true";
import { default as lastminuteodYBWDQg2WMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/lastminute.vue?macro=true";
import { default as mapVGU5lcaWmGMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/map.vue?macro=true";
import { default as dinbokningpzGVzfQIGnMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue?macro=true";
import { default as newsletterywajIoWD9HMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/newsletter.vue?macro=true";
import { default as offlineQfazBP4BKPMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/offline.vue?macro=true";
import { default as failedZNmS7nlkoEMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/payment/failed.vue?macro=true";
import { default as indexhTz5YUV8jHMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/review/index.vue?macro=true";
import { default as searchSVYhKQ01WqMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/search.vue?macro=true";
import { default as test5CJ7TTNwE4Meta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/test.vue?macro=true";
import { default as indexqfZCB829EiMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/tourleader/index.vue?macro=true";
import { default as verification_45done3uuqk8Y3GCMeta } from "/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/verification-done.vue?macro=true";
export default [
  {
    name: "tourleader-id",
    path: "/guider/:id()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "titinerary",
    path: "/dagsprogram",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "shipinfo",
    path: "/skib",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "omdome",
    path: "/omdome",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "mytrip",
    path: "/din-rejse",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "info-lectures",
    path: "/info/foredrag",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue")
  },
  {
    name: "info-connections",
    path: "/info/forbindelser",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue")
  },
  {
    name: "info-about",
    path: "/info/om",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "fn",
    path: "/fn",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "destination-slug",
    path: "/destination/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue")
  },
  {
    name: "competition",
    path: "/konkurrence",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "category",
    path: "/kategori",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue")
  },
  {
    name: "bookingfaq",
    path: "/boknings-faq",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "booking-finish",
    path: "/booking-finish",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking-confirmation",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "book-tripid",
    path: "/bestil/:tripid()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "auth",
    path: "/medlem",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue")
  },
  {
    name: "app-l",
    path: "/app/l",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "triptype-slug",
    path: "/:triptype()/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "triptype-slug-tab",
    path: "/:triptype()/:slug()/:tab()",
    meta: _91tab_93hfViW7zubcMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "activities",
    path: "/activities",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/activities.vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/app/index.vue")
  },
  {
    name: "app-l-jwt",
    path: "/app/l/:jwt()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "auth-slug",
    path: "/medlem/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue")
  },
  {
    name: "auth-callback",
    path: "/medlem/callback",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/callback.vue")
  },
  {
    name: "blackweek",
    path: "/blackweek",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/blackweek.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/index.vue")
  },
  {
    name: "book-tripid-step",
    path: "/bestil/:tripid()/:step()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "booking-confirmation-id",
    path: "/booking-confirmation/:id()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "booking-finish-ocr",
    path: "/booking-finish/:ocr()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "bookingfaq-ocr",
    path: "/boknings-faq/:ocr()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "byebyeie",
    path: "/byebyeie",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/byebyeie.vue")
  },
  {
    name: "calendar",
    path: "/kalender",
    meta: indexzaokc4rScsMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/index.vue")
  },
  {
    name: "calendar-lastminute",
    path: "/kalender/afbudsrejser",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/lastminute.vue")
  },
  {
    name: "campaign-name",
    path: "/kampagne/:name()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/[name].vue")
  },
  {
    name: "campaign",
    path: "/kampagne",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/index.vue")
  },
  {
    name: "catalogue",
    path: "/katalog",
    meta: catalogue5Y0oqvUoRDMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/catalogue.vue")
  },
  {
    name: "category-slug",
    path: "/kategori/:slug()",
    meta: _91slug_93hSMSLzevXiMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue")
  },
  {
    name: "competition-slug",
    path: "/konkurrence/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "complaint",
    path: "/reklamation",
    meta: complaint6KwVUoimMtMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/complaint.vue")
  },
  {
    name: "destination-slug-tab",
    path: "/destination/:slug()/:tab()",
    meta: _91tab_93ZQp1YORoDDMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue")
  },
  {
    name: "dinbokning-aktuell",
    path: "/dinbokning-aktuell",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue")
  },
  {
    name: "event-slug",
    path: "/event/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/[slug].vue")
  },
  {
    name: "event",
    path: "/event",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/faq.vue")
  },
  {
    name: "fn-id",
    path: "/fn/:id()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "giftcard-id",
    path: "/gavekort/:id()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue")
  },
  {
    name: "giftcard",
    path: "/gavekort",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/giftcard/index.vue")
  },
  {
    name: "hotels-name",
    path: "/hoteller/:name()",
    meta: _91name_93jGsRfZrCs7Meta || {},
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/hotels/[name].vue")
  },
  {
    name: "hotels",
    path: "/hoteller",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/hotels/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_93rAglRkd2a8Meta || {},
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "info-about-tab",
    path: "/info/om/:tab()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue")
  },
  {
    name: "info-connections-tab",
    path: "/info/forbindelser/:tab()",
    meta: _91tab_93LLkCSL4OfCMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue")
  },
  {
    name: "info-grouptravel",
    path: "/info/grupperejse",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/grouptravel.vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/index.vue")
  },
  {
    name: "info-lectures-tab",
    path: "/info/foredrag/:tab()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue")
  },
  {
    name: "lastminute",
    path: "/afbudsrejser",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/lastminute.vue")
  },
  {
    name: "map",
    path: "/kort",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/map.vue")
  },
  {
    name: "mytrip-tab",
    path: "/din-rejse/:tab()",
    meta: _91tab_939QYGNRSsUQMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "mytrip-dinbokning",
    path: "/mytrip/dinbokning",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue")
  },
  {
    name: "newsletter",
    path: "/nyhedsbrev",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/newsletter.vue")
  },
  {
    name: "offline",
    path: "/offline",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/offline.vue")
  },
  {
    name: "omdome-id",
    path: "/omdome/:id()",
    meta: _91id_93SpOCnLuw1XMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "payment-id",
    path: "/payment/:id()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "payment-failed",
    path: "/payment/failed",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/payment/failed.vue")
  },
  {
    name: "review",
    path: "/anmeldelse",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/review/index.vue")
  },
  {
    name: "search",
    path: "/søg",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/search.vue")
  },
  {
    name: "shipinfo-name",
    path: "/skib/:name()",
    meta: _91name_93JhvCpTXa8MMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/applications/rolfsbuss-web/pages/test.vue")
  },
  {
    name: "titinerary-slug",
    path: "/dagsprogram/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "tourleader-id-slug",
    path: "/guider/:id()/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "tourleader",
    path: "/guider",
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/tourleader/index.vue")
  },
  {
    name: "verification-done",
    path: "/verification-done",
    meta: verification_45done3uuqk8Y3GCMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-12-12-1123/rtg-monorepo/src/layers/web/pages/verification-done.vue")
  }
]